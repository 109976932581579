import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import HomePage from './Pages/HomePage';
import './App.css';
// import ExportPage from './Pages/ExportPage';
import { Analytics } from '@vercel/analytics/react';
import LandingPage from './Pages/LandingPage';



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
      </Routes>
      <Analytics />
    </Router>
  );
}

export default App;
// landingPage.jsx
import React, { useState, useRef, useEffect } from 'react';
import './LandingPage.css';

const LandingPage = () => {
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [role, setRole] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [submissionMessage, setSubmissionMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const features = [
    {
      icon: "🤖",
      title: "AI-Assisted Idea Generation",
    },
    {
      icon: "📋",
      title: "Seamless idea review process and manager view",
    },
    {
      icon: "🌀",
      title: "Innovation Challenges & Company Portal",
    },
    {
      icon: "🎮",
      title: "Gamification & Reward System",
    }
  ];

  const products = [
    {
      name: "For Employees",
      title: "AI-Assisted Idea Generation (Harmoni AI Helper)",
      description: "Transform your unstructured “brain dumps” into coherent project proposals. Harmoni’s AI refines rough concepts into actionable templates—so every idea starts on solid ground.",
      image: "Employee-Submit-Project-Idea.png"
    },
    {
      name: "For Managers",
      title: "Seamless Idea Review Process & Manager View",
      description: "Managers receive project ideas from their teams, provide feedback & ideas to the employee, and decide whether to approve, revise, or shelve—keeping the path from concept to execution clear and efficient.",
      image: "Manager-Team-Submissions-Menu.png"
    },
    {
      name: "For Teams, Manager and Employees",
      title: "Innovation Challenges & Company Portal",
      description: "Focus creative energy on key business problems with custom challenges. Keep everyone aligned through a centralized portal featuring real-time updates, announcements, and a shared hub for collaboration.",
      image: "Innovation-Challenge-Company.png"
    },
    {
      name: "For Teams, Managers and Employees",
      title: "Gamification & Reward System",
      description: "Drive engagement with points, badges, and leaderboards. Recognize top contributors, celebrate achievements, and foster a culture where every breakthrough is rewarded.",
      image: "Employee-Innovation-Reward.png"
    }
  ];

  const _plans = [
    {
      name: "Free",
      description: "Give us a try for free",
      price: 0,
      features: [
        "1 seat",
        "1 active project",
        "Ulimited viewers",
        "10 blocks"
      ]
    },
    {
      name: "Starter",
      description: "Best for individual desginers",
      price: 29,
      features: [
        "1 seat",
        "3 active project",
        "Ulimited viewers",
        "100 blocks",
        "CSV Downloader",
        "Password protection"
      ]
    },
    {
      name: "Premium",
      description: "Get your team together",
      price: 79,
      features: [
        "10 seat",
        "10 active project",
        "Ulimited viewers",
        "Unlimited blocks",
        "CSV Downloader",
        "Password protection",
        "Customization"
      ]
    }
  ];

  const featureItems = [
    {
      icon: "🧠",
      title: "Transform Unstructured Thoughts",
      description: "Harmoni’s AI refines rough ideas into polished proposals that can be pitched for implementation—helping you turn brainstorms into real opportunities."
    },
    {
      icon: "🏆",
      title: "Earn Rewards & Recognition", 
      description: "Get acknowledged for every valuable contribution. With transparent rewards, you’ll always know what you’ll receive for innovative ideas."
    },
    {
      icon: "🎭",
      title: "Anonymous Idea Submissions",
      description: "All project ideas are submitted privately to eliminate bias during review and iteration, ensuring merit-based decisions."
    },
    {
      icon: "💡",
      title: "Cultivate an Innovative Culture",
      description: "Encourage employees to think big and drive continuous improvements throughout the organization"
    },
    {
      icon: "⚡",
      title: "Receive Targeted Solutions",
      description: "Company executives launch 'Innovation Challenges,' and your top minds submit ideas to solve them for your review."
    },
    {
      icon: "📈",
      title: "Drive Measurable ROI",
      description: "Turn employee ideas into concrete projects, boosting your competitive advantage while maximizing returns on talent."
    },
    {
      icon: "⭐",
      title: "Receive Rewards, Promotions and Praise",
      description: "Harmoni ensures that high-performing managers are visible and receive pre-determined rewards as well as recognition in company-wide leaderboards."
    },
    {
      icon: "✅",
      title: "Quick and Easy Project Idea Reviews",
      description: "Refine and approve ideas in a seamless interface—provide notes, request revisions, and track decisions with ease."
    },
    {
      icon: "🎯",
      title: "Set Team Innovation Challenges",
      description: "Use your insight into team needs to create targeted ‘Innovation Challenges,’ spurring solutions that directly address department goals."
    }
  ];

  const featureCards = [
    {
      title: "LOREM IPSUM",
      description: "Officia culpa tempor eu dolore dolor esse ex incididunt ea...",
      details: "Aliquip fugiat nostrud nulla eu exercitation culpa officia irure dolor elit eu duis. Irure exercitation ex ad id anim fugiat mollit magna et. Proident magna exercitation amet irure est..."
    },
    {
      title: "LOREM IPSUM",
      description: "Officiaaa culpa tempor eu dolore dolor esse ex incididunt...",
      details: "Aliquip fdassaddasugiat nostrud nulla eu exercitation culpa officia irure dolor elit eu duis. Irure exercitation ex ad id anim fugiat mollit magna et. Proident magna..."
    },
    {
      title: "LOREM IPSUM",
      description: "Officia culpa tempor eu dolore dolor esse ex incididunt ea...",
      details: "Aliquip fugiat nostrud nulla eu exercitation culpa officia irure dolor elit eu duis. Irure exercitation ex ad id anim fugiat mollit magna et. Proident magna exercitation amet irure est..."
    },
    {
      title: "LOREM IPSUM",
      description: "Officia culpa tempor eu dolore dolor esse ex incididunt ea...",
      details: "Aliquip fugiat nostrud nulla eu exercitation culpa officia irure dolor elit eu duis. Irure exercitation ex ad id anim fugiat mollit magna et. Proident magna exercitation amet irure est..."
    },
    {
      title: "LOREM IPSUM",
      description: "Officia culpa tempor eu dolore dolor esse ex incididunt ea...",
      details: "Aliquip fugiat nostrud nulla eu exercitation culpa officia irure dolor elit eu duis. Irure exercitation ex ad id anim fugiat mollit magna et. Proident magna exercitation amet irure est..."
    },
    {
      title: "LOREM IPSUM",
      description: "Officia culpa tempor eu dolore dolor esse ex incididunt ea...",
      details: "Aliquip fugiat nostrud nulla eu exercitation culpa officia irure dolor elit eu duis. Irure exercitation ex ad id anim fugiat mollit magna et. Proident magna exercitation amet irure est..."
    }
  ];

  const testimonials = [
    {
      role: "HR Manager",
      company: "Insurance Company",
      quote: "We've never had such an easy way to collect and evaluate employee ideas. Even at this early stage, teams are more engaged.",
      rating: 5
    },
    {
      role: "Research Department Head",
      company: "University Research Institute",
      quote: "Our managers appreciate the AI-assisted review flow, and researchers love the easy submission process.",
      rating: 5
    },
    {
      role: "Startup Founder",
      company: "Fintech Startup",
      quote: "We've already seen employees more willing to submit ideas. Harmoni's streamlined process has us excited for the full launch.",
      rating: 5
    }
  ];

  const carouselRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      const _scrollWidth = carousel.scrollWidth;
      const clientWidth = carousel.clientWidth;
      
      // Clone items to create the illusion of an infinite loop
      const itemsToClone = Math.ceil(clientWidth / (300 + 20)) + 1; // 300px is card width, 20px is gap
      const fragment = document.createDocumentFragment();
      
      for (let i = 0; i < itemsToClone; i++) {
        featureCards.forEach((card, index) => {
          const clone = carousel.children[index].cloneNode(true);
          fragment.appendChild(clone);
        });
      }
      
      carousel.appendChild(fragment);
    }
  }, []);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - carouselRef.current.offsetLeft);
    setScrollLeft(carouselRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    carouselRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleScroll = () => {
    const carousel = carouselRef.current;
    if (carousel.scrollLeft === 0) {
      carousel.scrollLeft = carousel.scrollWidth / 2;
    } else if (carousel.scrollLeft + carousel.clientWidth >= carousel.scrollWidth) {
      carousel.scrollLeft = carousel.scrollWidth / 2 - carousel.clientWidth;
    }
  };

  const handleFeatureClick = (index) => {
    setSelectedFeature(selectedFeature === index ? null : index);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEarlyAccess = async (e) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      setSubmissionMessage('Please enter a valid email address');
      return;
    }
    if (!firstName || !lastName || !email || !company || !role) {
      setSubmissionMessage('Please fill in all required fields');
      return;
    }
    setIsSubmitting(true);
    setSubmissionMessage('');
    
    try {
      const formData = {
        firstName,
        lastName,
        email,
        company,
        role,
        additionalDetails
      };

      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
      const response = await fetch(`${API_BASE_URL}/api/email-documents/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setSubmissionMessage('Thank you for your interest! Check your email for further details.');
        // Clear form
        setFirstName('');
        setLastName('');
        setEmail('');
        setCompany('');
        setRole('');
        setAdditionalDetails('');
      } else {
        const errorData = await response.json();
        setSubmissionMessage(errorData.error || 'Something went wrong. Please try again.');
      }
    } catch (error) {
      setSubmissionMessage('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="landing-page">
      <header className="header">
        <div className="logo">
          <img src="Logo.png" alt="logo" className='logo-image' />
          Harmoni
        </div>
        <nav className="nav">
          <a href="/features" className="nav-item">Features</a>
          <a href="/pricing" className="nav-item">Benefits</a>
          <button className="sign-up-button">Get Early Access</button>
        </nav>
      </header>
      <main className="main-content">
        <div className="left-section">
          <div className="tagline">ACCELERATE INNOVATION</div>
          <h1 className="main-heading">Fuel Your Next Breakthrough with Employee-Driven Ideas.</h1>
          <p className="description">
          Harmoni helps you harness your team's collective intelligence to boost innovation, 
          improve efficiency, and gain a competitive edge. 
          <br/>
          <br/>
          Transform employees' ideas into real business impact with our AI-powered platform.
          </p>
          <div className="cta-container">
            <div className="input-section">
              {submissionMessage && (
                <div className={`submission-message ${submissionMessage.includes('Thank you') ? 'success' : 'error'}`}>
                  {submissionMessage}
                </div>
              )}
              <div className="left-inputs">
                <div className="email-input-container">
                  <div className="name-row">
                    <div className="name-input">
                      <label className="email-label">First Name*</label>
                      <input 
                        type="text" 
                        placeholder="John" 
                        className="email-input"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className="name-input">
                      <label className="email-label">Last Name*</label>
                      <input 
                        type="text" 
                        placeholder="Smith" 
                        className="email-input"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <label className="email-label">Your Email*</label>
                  <input 
                    type="email" 
                    placeholder="name@email.com" 
                    className="email-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label className="email-label">Company*</label>
                  <input 
                    type="text"
                    placeholder="Google" 
                    className="email-input"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                  <label className="email-label">Role*</label>
                  <input 
                    type="text" 
                    placeholder="Software Engineer" 
                    className="email-input"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  />
                </div>
              </div>
              <div className="right-inputs">
                <label className="email-label">Additional Details (Optional)</label>
                <textarea 
                  placeholder="Tell us more about your needs..." 
                  className="email-input textarea-input"
                  rows={8}
                  value={additionalDetails}
                  onChange={(e) => setAdditionalDetails(e.target.value)}
                />
                <button 
                  className="cta-button" 
                  onClick={handleEarlyAccess}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Submitting...' : 'Get Early Access'}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="right-section">
          <img className="image" src="landingtest.webp" alt="SaaS illustration" />
        </div>
      </main>
      <div className="section-header">
        <div className="tag">EARLY FEEDBACK FROM OUR PILOT USERS</div>
        <div className="testimonials-section">
          <div className="testimonials-grid">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="testimonial-card">
                <div className="testimonial-header">
                  <div className="testimonial-info">
                    <h3 className="testimonial-role">{testimonial.role}</h3>
                    <p className="testimonial-company">{testimonial.company}</p>
                  </div>
                </div>
                <div className="testimonial-rating">
                  {"★".repeat(testimonial.rating)}
                </div>
                <p className="testimonial-quote">"{testimonial.quote}"</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="features-grid-section">
        <div className="feature-selection">
          <div className="section-header">
            <div className="tag-benefits">BENEFITS</div>
              <h2>Benefits For You</h2>
            </div>
          </div>
        <div className="features-columns">
          <div className="feature-column">
            <h2 className="column-header">For Employees</h2>
            {featureItems.slice(0, 3).map((item, index) => (
              <div key={index} className="benefit-item">
                <div className="benefit-header">
                  <div className="benefit-icon">{item.icon}</div>
                  <h3>{item.title}</h3>
                </div>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
          <div className="feature-column">
            <h2 className="column-header">For Companies</h2>
            {featureItems.slice(3, 6).map((item, index) => (
              <div key={index + 3} className="benefit-item">
                <div className="benefit-header">
                  <div className="benefit-icon">{item.icon}</div>
                  <h3>{item.title}</h3>
                </div>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
          <div className="feature-column">
            <h2 className="column-header">For Managers</h2>
            {featureItems.slice(6, 9).map((item, index) => (
              <div key={index + 6} className="benefit-item">
                <div className="benefit-header">
                  <div className="benefit-icon">{item.icon}</div>
                  <h3>{item.title}</h3>
                </div>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="feature-selections-wrapper">
        <div className="feature-selection">
          <div className="section-header">
            <div className="tag">FEATURES</div>
            <h2>Discover How Harmoni Works</h2>
          </div>          
          <div className="feature-container">
            <div className="feature-list">
              {features.map((feature, index) => (
                <div
                  key={index}
                  className={`feature-item ${selectedFeature === index ? 'selected' : ''}`}
                  onClick={() => handleFeatureClick(index)}
                >
                  <div className="feature-icon">{feature.icon}</div>
                  <div className="feature-text">
                    <h3>{feature.title}</h3>
                    {selectedFeature === index && <p>{feature.description}</p>}
                  </div>
                </div>
              ))}
            </div>
            {selectedFeature !== null && (
              <div className="product-display visible">
                <div className="product-header">
                  <span>{products[selectedFeature % 4].name}</span>
                </div>
                <h2>{products[selectedFeature % 4].title}</h2>
                <p>{products[selectedFeature % 4].description}</p>
                <img src={products[selectedFeature % 4].image} alt="Product screenshot" className="product-image" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="features-section" style={{display: 'none'}}>
        <h2 className="features-heading">What are you signing in for?</h2>
        <div 
          className="feature-cards-container" 
          ref={carouselRef}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onMouseMove={handleMouseMove}
          onScroll={handleScroll}
        >
          {featureCards.map((card, index) => (
            <div key={index} className="feature-card">
              <div className="feature-image">{card.title}</div>
              <h3>{card.description}</h3>
              <p>{card.details}</p>
            </div>
          ))}
        </div>
      </div>
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <div className="footer-logo">
              <img src="Logo.png" alt="logo" className='logo-image' />
              Harmoni
            </div>
            <p>Transform your company's innovation process</p>
          </div>
          <div className="footer-section">
            <h3>Product</h3>
            <a href="/features">Features</a>
            <a href="/benefits">Benefits</a>
            <a href="/pricing">Pricing</a>
          </div>
          <div className="footer-section">
            <h3>Company</h3>
            <a href="/">About Us</a>
            <a href="/">Contact</a>
            <a href="/">Privacy Policy</a>
          </div>
          <div className="footer-section">
            <h3>Get Started</h3>
            <button className="footer-cta">Get Early Access</button>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Harmoni. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;